// Import icons from their respective paths
// Languages
import javaIcon from "../assets/images/tech-icons/tech-languages/java.png";
import pythonIcon from "../assets/images/tech-icons/tech-languages/python.png";
import javascriptIcon from "../assets/images/tech-icons/tech-languages/javascript.png";
import htmlIcon from "../assets/images/tech-icons/tech-languages/html.png";
import cssIcon from "../assets/images/tech-icons/tech-languages/css.png";
import swiftIcon from "../assets/images/tech-icons/tech-languages/swift.png";

// Frameworks
import reactIcon from "../assets/images/tech-icons/tech-frameworks/reactjs.png";
import nodejsIcon from "../assets/images/tech-icons/tech-frameworks/nodejs.png";
import nextjsIcon from "../assets/images/tech-icons/tech-frameworks/nextjs.png";
import npmIcon from "../assets/images/tech-icons/tech-frameworks/npm.png";
import materialuiIcon from "../assets/images/tech-icons/tech-frameworks/materialui.png";
// import chakrauiIcon from "../assets/images/tech-icons/tech-frameworks/chakraui.png";
import tailwindcssIcon from "../assets/images/tech-icons/tech-frameworks/tailwindcss.png";

// Development Tools
import gitIcon from "../assets/images/tech-icons/tech-frameworks/git.png";
import githubIcon from "../assets/images/tech-icons/tech-frameworks/github.png";
import intellijIcon from "../assets/images/tech-icons/tech-frameworks/intellij.png";
import pycharmIcon from "../assets/images/tech-icons/tech-frameworks/pycharm.png";

// Databases and Storage
import mongodbIcon from "../assets/images/tech-icons/tech-frameworks/mongodb.png";
import mysqlIcon from "../assets/images/tech-icons/tech-frameworks/mysql.png";
import firebaseIcon from "../assets/images/tech-icons/tech-frameworks/firebase.png";

// Other Technologies
import azureIcon from "../assets/images/tech-icons/tech-frameworks/azure.png";
import expoIcon from "../assets/images/tech-icons/tech-frameworks/expo.png";

// Export the organized skills as arrays of objects
export const technicalLanguages = [
  { src: javaIcon, alt: "Java" },
  { src: pythonIcon, alt: "Python" },
  { src: javascriptIcon, alt: "JavaScript" },
  { src: htmlIcon, alt: "HTML" },
  { src: cssIcon, alt: "CSS" },
  { src: swiftIcon, alt: "Swift" },
];

export const frameworksAndLibraries = [
  { src: reactIcon, alt: "React" },
  { src: nodejsIcon, alt: "Node.js" },
  { src: nextjsIcon, alt: "Next.js" },
  { src: npmIcon, alt: "NPM" },
  { src: materialuiIcon, alt: "Material-UI" },
  { src: tailwindcssIcon, alt: "Tailwind CSS" },
];

export const developmentTools = [
  { src: gitIcon, alt: "Git" },
  { src: githubIcon, alt: "GitHub" },
  { src: intellijIcon, alt: "IntelliJ IDEA" },
  { src: pycharmIcon, alt: "PyCharm" },
];

export const databasesAndStorage = [
  { src: mongodbIcon, alt: "MongoDB" },
  { src: mysqlIcon, alt: "MySQL" },
  { src: firebaseIcon, alt: "Firebase" },
];

export const otherTechnologies = [
  { src: azureIcon, alt: "Azure" },
  { src: expoIcon, alt: "Expo" },
];
