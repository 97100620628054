import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  // State to manage mobile menu toggle
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Toggle function for mobile menu
  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const location = useLocation();

  // Helper function to handle navigation and scroll
  const handleNavClick = (sectionId) => {
    if (location.pathname !== "/") {
      // If not on the homepage, navigate to home and scroll to the section
      window.location.href = `/#${sectionId}`;
    } else {
      // If already on the homepage, scroll to the section directly
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  // Function to handle logo click (scroll to top of Hero section)
  const handleLogoClick = () => {
    if (location.pathname !== "/") {
      // If not on the homepage, navigate to home and scroll to the top
      window.location.href = "/#";
    } else {
      // If already on the homepage, scroll to the top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <nav className="bg-darkBackground bg-opacity-75 fixed w-full z-50 top-0 left-0 backdrop-blur-sm">
      <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
        {/* Logo and Name - Click to scroll to the top of the Hero section */}
        <button
          onClick={handleLogoClick}
          className="flex items-center space-x-3"
        >
          <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
            Fredy Sorto
          </span>
        </button>

        {/* Hamburger Icon - Visible on mobile screens */}
        <div className="md:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={handleMobileMenuToggle}
          >
            {/* Hamburger icon: three horizontal lines */}
            <div className="space-y-1">
              <span className="block w-6 h-0.5 bg-white"></span>
              <span className="block w-6 h-0.5 bg-white"></span>
              <span className="block w-6 h-0.5 bg-white"></span>
            </div>
          </button>
        </div>

        {/* Navigation Links - Hidden on mobile, visible on larger screens */}
        <div className="hidden md:flex md:w-auto" id="navbar-sticky">
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0">
            <li>
              <button
                onClick={() => handleNavClick("about")}
                className="block py-2 pl-3 pr-4 text-white hover:text-neonPink md:p-0"
              >
                About
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavClick("skills")}
                className="block py-2 pl-3 pr-4 text-white hover:text-neonPink md:p-0"
              >
                Skills
              </button>
            </li>
            <li>
              <button
                onClick={() => handleNavClick("projects")}
                className="block py-2 pl-3 pr-4 text-white hover:text-neonPink md:p-0"
              >
                Projects
              </button>
            </li>
          </ul>
        </div>

        {/* Mobile Navigation Links - Visible when the hamburger menu is open */}
        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-16 right-0 bg-darkBackground w-full">
            <ul className="flex flex-col items-center p-4 space-y-4">
              <li>
                <a
                  href="#about"
                  className="block py-2 pl-3 pr-4 text-white hover:text-neonPink"
                  onClick={handleMobileMenuToggle}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#skills"
                  className="block py-2 pl-3 pr-4 text-white hover:text-neonPink"
                  onClick={handleMobileMenuToggle}
                >
                  Skills
                </a>
              </li>
              <li>
                <a
                  href="#projects"
                  className="block py-2 pl-3 pr-4 text-white hover:text-neonPink"
                  onClick={handleMobileMenuToggle}
                >
                  Projects
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
