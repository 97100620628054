import React from "react";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa"; // Importing icons

const Footer = () => {
  return (
    <footer className="relative z-20 bg-neonPink text-white py-4 text-center">
      <div className="container mx-auto">
        <p>
          &copy; {new Date().getFullYear()} Fredy Sorto. All rights reserved.
        </p>
        <div className="flex justify-center space-x-6 mt-2">
          {/* LinkedIn Icon */}
          <a
            href="https://www.linkedin.com/in/fredysorto/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-neonBlue"
          >
            <FaLinkedin size={24} />
          </a>

          {/* GitHub Icon */}
          <a
            href="https://github.com/fredygsorto"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-neonBlue"
          >
            <FaGithub size={24} />
          </a>

          {/* Email Icon */}
          <a
            href="mailto:fredygsorto@gmail.com"
            className="text-white hover:text-neonBlue"
          >
            <FaEnvelope size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
