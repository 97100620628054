// src/components/Projects.js
import React from "react";
import { projects } from "../data/projectData"; // Adjust import path for your projectData.js
import ProjectCard from "./ProjectCard";
import {
  technicalLanguages,
  frameworksAndLibraries,
  developmentTools,
  databasesAndStorage,
  otherTechnologies,
} from "./techIcons"; // Adjust import path for your tech icons

const combinedIcons = [
  ...technicalLanguages,
  ...frameworksAndLibraries,
  ...developmentTools,
  ...databasesAndStorage,
  ...otherTechnologies,
];

const Projects = () => (
  <section
    id="projects"
    className="relative z-20 bg-darkBackground text-white px-4 py-16 md:px-8 lg:px-16"
  >
    <div className="max-w-screen-lg mx-auto">
      <h2 className="text-5xl md:text-6xl font-extrabold pixelFont text-neonPink mb-12">
        My Projects
      </h2>

      <div className="space-y-12">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            title={project.title}
            description={project.description}
            techStack={project.techStack}
            liveLink={project.liveLink}
            repoLink={project.repoLink}
            combinedIcons={combinedIcons}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Projects;
