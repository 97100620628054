import { useNavigate } from "react-router-dom";
import Header from "../components/Header"; // Reuse your Header component
import ParticlesBackground from "../components/ParticlesBackground"; // Reuse your Particles background component

import resumeLink from "../assets/FredySorto_Resume.pdf"; // Import the resume link

const ResumePage = () => {
  const navigate = useNavigate();

  const downloadResume = () => {
    const link = document.createElement("a");
    link.href = resumeLink;
    link.download = "FredySorto_Resume.pdf"; // Name for the download
    link.click();
  };

  return (
    <>
      <Header />
      <ParticlesBackground />
      <div className="relative min-h-screen flex flex-col items-center justify-center ">
        <div className="relative z-10 p-8 shadow-lg w-full md:w-10/12 lg:w-8/12 h-[80vh]">
          <iframe
            src={resumeLink}
            title="Fredy Sorto Resume"
            width="100%"
            height="100%"
            className="border-none"
            style={{ minHeight: "80vh" }}
          ></iframe>
        </div>

        <div className="relative z-10 flex space-x-4 mt-10">
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 bg-gray-800 text-white rounded-lg"
          >
            Back
          </button>
          <button
            onClick={downloadResume}
            className="px-4 py-2 bg-neonPink text-white rounded-lg"
          >
            Download
          </button>
        </div>
      </div>
    </>
  );
};

export default ResumePage;
