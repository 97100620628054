// src/components/Hero.js
import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa"; // Icons for social links
import { Link } from "react-router-dom";

import Programmer from "../assets/images/programmer.png";
import ParticlesBackground from "./ParticlesBackground";

const Hero = () => {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      {/* Particles Background, contained only within Hero */}
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <ParticlesBackground />
      </div>

      {/* Hero Content */}
      <section className="relative z-10 h-full flex flex-col justify-center items-center">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            {/* "Hi, I am" */}
            <h1 className=" max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-8xl dark:text-white">
              Hi, I am
            </h1>
            {/* "Fredy." */}
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-8xl dark:text-neonBlue text-neonBlue">
              Fredy.
            </h1>

            {/* Buttons and Icons */}
            <div className="flex items-center space-x-4 mb-6">
              <Link
                to="/resume"
                className="text-base inline-flex items-center justify-center px-4 py-2 font-medium text-center text-white rounded-lg bg-neonPink hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                My Resume
              </Link>
              <a
                href="https://www.linkedin.com/in/fredysorto/"
                className="text-white"
                target="blank"
              >
                <FaLinkedin size={32} />
              </a>
              <a
                href="https://github.com/fredygsorto"
                className="text-white"
                target="blank"
              >
                <FaGithub size={32} />
              </a>
              <a href="mailto:fredygsorto@gmail.com" className="text-white">
                <FaEnvelope size={32} />
              </a>
            </div>

            {/* Typing Animation */}
            <h1 className="text-4xl mb-4 text-white">
              I am a{" "}
              <span className="text-neonBlue font-bold ">
                <Typewriter
                  words={[
                    "Full Stack Developer.",
                    "Software Engineer.",
                    "Mobile Developer.",
                    "Web Developer.",
                    "Technology Associate.",
                  ]}
                  loop={true}
                  cursor
                  cursorStyle="|"
                  typeSpeed={100}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </h1>
          </div>

          {/* Optional Image */}
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={Programmer} alt="mockup" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
