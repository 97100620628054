// src/components/ProjectCard.js
import React from "react";
import { motion } from "framer-motion";
import { FaGithub } from "react-icons/fa";

const ProjectCard = ({
  title,
  description,
  techStack,
  liveLink,
  repoLink,
  combinedIcons,
}) => (
  <motion.div
    className="bg-gray-800 p-6 rounded-lg shadow-2xl transition transform hover:-translate-y-2 hover:shadow-lg"
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <h3 className="text-3xl font-bold text-neonPink">{title}</h3>
    <p className="mt-4 text-gray-300">{description}</p>

    {/* Tech Stack */}
    <div className="mt-4">
      <h4 className="text-lg text-white font-semibold">Tech Stack</h4>
      <div className="flex space-x-3 mt-2">
        {techStack.map((tech, index) => {
          const icon = combinedIcons.find((t) => t.alt === tech);
          return icon ? (
            <img key={index} src={icon.src} alt={tech} className="w-8 h-8" />
          ) : (
            <span key={index} className="text-white">
              {tech}
            </span>
          );
        })}
      </div>
    </div>

    {/* Links */}
    <div className="flex space-x-4 mt-6">
      {liveLink && (
        <a
          href={liveLink}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-neonPink text-white py-2 px-4 rounded-lg transition hover:bg-pink-500"
        >
          Live Demo
        </a>
      )}
      <a
        href={repoLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center text-white text-2xl w-12 h-12 rounded-full bg-neonBlue transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-500"
      >
        <FaGithub />
      </a>
    </div>
  </motion.div>
);

export default ProjectCard;
