import React from "react";
import { motion } from "framer-motion";
import {
  technicalLanguages,
  frameworksAndLibraries,
  developmentTools,
  databasesAndStorage,
} from "./techIcons";

// Icon component for individual skill icons
const SkillIcon = ({ src, alt }) => (
  <motion.div
    className="flex justify-center items-center w-20 h-20 md:w-32 md:h-32 rounded-full bg-gray-800 p-4 shadow-xl"
    whileHover={{
      scale: 1.2,
      y: -10,
      boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.3)",
    }}
    whileTap={{ scale: 0.9 }}
    transition={{ type: "spring", stiffness: 300, damping: 10 }}
  >
    <img
      src={src}
      alt={alt}
      className="w-16 h-16 md:w-20 md:h-20 object-contain"
    />
  </motion.div>
);

const Skills = () => (
  <section
    id="skills"
    className="relative z-20 bg-darkBackground text-white px-4 py-16 md:px-8 lg:px-16"
  >
    <div className="max-w-screen-lg mx-auto">
      <h2 className="text-5xl md:text-6xl font-extrabold pixelFont text-neonPink mb-8 text-left">
        My Skills.
      </h2>

      {/* Technical Languages */}
      <h3 className="text-2xl md:text-3xl font-bold text-white mb-6">
        Technical Languages
      </h3>
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-8 justify-items-center">
        {technicalLanguages.map((skill, index) => (
          <SkillIcon key={index} src={skill.src} alt={skill.alt} />
        ))}
      </div>

      {/* Frameworks and Libraries */}
      <h3 className="text-2xl md:text-3xl font-bold text-white mt-12 mb-6">
        Frameworks and Libraries
      </h3>
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-8 justify-items-center">
        {frameworksAndLibraries.map((skill, index) => (
          <SkillIcon key={index} src={skill.src} alt={skill.alt} />
        ))}
      </div>

      {/* Databases and Storage */}
      <h3 className="text-2xl md:text-3xl font-bold text-white mt-12 mb-6">
        Databases and Storage
      </h3>
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-8 justify-items-center">
        {databasesAndStorage.map((skill, index) => (
          <SkillIcon key={index} src={skill.src} alt={skill.alt} />
        ))}
      </div>

      {/* Development Tools */}
      <h3 className="text-2xl md:text-3xl font-bold text-white mt-12 mb-6">
        Development Tools
      </h3>
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-8 justify-items-center">
        {developmentTools.map((skill, index) => (
          <SkillIcon key={index} src={skill.src} alt={skill.alt} />
        ))}
      </div>
    </div>
  </section>
);

export default Skills;
