import React from "react";
import Tilt from "react-parallax-tilt";

// Import the images for frontend and backend developer
import frontendImg from "../assets/images/frontend.png"; // Adjust the path accordingly
import backendImg from "../assets/images/backend.png"; // Adjust the path accordingly

const About = () => {
  return (
    <section
      id="about"
      className="relative z-20 bg-darkBackground text-white px-4 py-16 md:px-8 lg:px-16"
    >
      <div className="max-w-screen-lg mx-auto">
        {/* Section Title */}
        <h2 className="text-5xl md:text-6xl font-extrabold pixelFont text-neonPink mb-8 text-left">
          About Me
        </h2>

        {/* Introduction Paragraph */}
        <div className="text-left mb-8">
          <h3 className="text-4xl font-bold text-neonBlue">Introduction.</h3>
          <p className="text-lg md:text-xl leading-relaxed mt-4 text-gray-300">
            Hello, I'm Fredy, a dedicated Full Stack Developer with a passion
            for creating responsive, visually engaging applications. My journey
            in the tech world has been driven by curiosity and a thirst for
            continuous learning.
          </p>
          <p className="text-lg md:text-xl leading-relaxed mt-4 text-gray-300">
            I specialize in front-end and back-end development, with a focus on
            JavaScript frameworks, RESTful APIs, and scalable web applications.
            I take pride in writing clean, efficient code, and I’m always
            looking to enhance my skill set with the latest technologies.
          </p>
          <p className="text-lg md:text-xl leading-relaxed mt-4 text-gray-300">
            I'm excited to work on projects that push boundaries, where I can
            apply my passion for problem-solving and innovation to help bring
            unique ideas to life.
          </p>
        </div>

        {/* Skill Highlights with Tilt effect */}
        <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {/* Frontend Developer */}
          <Tilt
            tiltMaxAngleX={20}
            tiltMaxAngleY={20}
            perspective={1000}
            transitionSpeed={1000}
            glareEnable={true}
            glareMaxOpacity={0.5}
            glareColor="#ffffff"
            glarePosition="all"
            className="w-full md:w-60 h-60 bg-gradient-to-b from-pink-500 to-blue-500 p-6 rounded-lg shadow-lg text-center transition-transform duration-500 hover:scale-105 hover:shadow-2xl"
          >
            <img
              src={frontendImg}
              alt="Frontend Developer"
              className="w-28 h-28 mx-auto mb-4"
            />
            <h4 className="text-xl font-bold text-black">Frontend Developer</h4>
          </Tilt>

          {/* Backend Developer */}
          <Tilt
            tiltMaxAngleX={20}
            tiltMaxAngleY={20}
            perspective={1000}
            transitionSpeed={1000}
            glareEnable={true}
            glareMaxOpacity={0.5}
            glareColor="#ffffff"
            glarePosition="all"
            className="w-full md:w-60 h-60 bg-gradient-to-b from-pink-500 to-blue-500 p-6 rounded-lg shadow-lg text-center transition-transform duration-500 hover:scale-105 hover:shadow-2xl"
          >
            <img
              src={backendImg}
              alt="Backend Developer"
              className="w-28 h-28 mx-auto mb-4"
            />
            <h4 className="text-xl font-bold text-black">Backend Developer</h4>
          </Tilt>
        </div>
      </div>
    </section>
  );
};

export default About;
