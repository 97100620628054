// src/data/projectData.js
export const projects = [
  {
    title: "Spotify X Bot",
    description:
      "Created a Spotify X Bot that will tweet out the song you are currently listening to on Spotify. The bot is hosted on PythonAnywhere and uses the Spotify API to get the song data and the Twitter API to tweet it out. The bot is written in Python.",
    techStack: ["Python", "Spotify API", "Twitter API"],
    liveLink: "https://x.com/fredynowplaying",
    repoLink: "",
  },
  {
    title: "Aware City App",
    description:
      "Introducing 'AwareCity,' a mobile app designed to aid New York's homeless community. It features Home, Map, and Charity tabs, offering hotlines, FAQs, shelter and food resources on a map, and a list of supportive organizations. My contributions involved designing the user interface, creating the map screen with real-time resource location, and implementing an efficient data structure for user proximity-based sorting. These enhancements improved the app's functionality and accessibility for homeless individuals.",
    techStack: ["React", "Expo"],
    liveLink: "https://awarecity.vercel.app/",
    repoLink: "https://github.com/fredygsorto/Aware-City",
  },
  {
    title: "AI Stock Predictor",
    description:
      "Developed a sentiment analysis component using NLTK to analyze sentiment in Yahoo Finance headlines, enabling nuanced market sentiment insights for Tesla stock trading decisions. Utilized scikit-learn to implement and fine-tune machine learning models, such as Support Vector Regression and Linear Regression, to forecast Tesla stock price movements based on sentiment analysis results.",
    techStack: ["Python", "NLTK", "scikit-learn"],
    liveLink: "https://live-demo.com",
    repoLink: "https://github.com/fredygsorto/AI-Stock-Predictor",
  },
  {
    title: "Money Management Tool",
    description:
      "Led the creation of 'Money Management Tool,' a user-friendly expense tracking web application for youth. Leveraged ReactJS to design interactive interfaces, making financial management accessible and promoting financial literacy. Additionally, engineered a secure and efficient backend using Node.js and Google Firestore, ensuring strong user authentication and meticulous income and expense tracking.",
    techStack: ["React", "Node.js", "Firebase"],
    liveLink: "https://mmtool.vercel.app/login",
    repoLink: "https://github.com/fredygsorto/mmt-react",
  },
  {
    title: "Weather Drip iOS",
    description:
      "Created a cutting-edge feature within the 'Weather Drip' iOS app using Swift and Xcode, enabling temperature trend analysis. This feature offers users tailored clothing recommendations for daily planning and personalized guidance. Additionally, integrated the AccuWeather API to provide up-to-the-minute weather forecasts, ensuring precise information and enhancing the app's user experience for daily planning.",
    techStack: ["Swift"],
    liveLink: null,
    repoLink: "https://github.com/fredygsorto/Weather-Drip-iOS",
  },
];
